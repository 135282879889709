import { initializeApp } from 'firebase/app';
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
  apiKey: "AIzaSyC3qhF062_Vg8aJFZN4mkkIpmedCKPSnHw",
  authDomain: "lemon-exp.firebaseapp.com",
  projectId: "lemon-exp",
  storageBucket: "lemon-exp.appspot.com",
  messagingSenderId: "13589844592",
  appId: "1:13589844592:web:d9664f64398f64b6e5dab5",
  measurementId: "G-VTMKFW209X"
};

  export const app = initializeApp(firebaseConfig);
  export const db = getFirestore(app);
  export const auth = getAuth(app);
  export const storage = getStorage(app);
