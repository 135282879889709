// ES6 module syntax
import LocalizedStrings from 'react-localization';

export const strings = new LocalizedStrings({
 en:{
   'language':"en",
  'Dom':"Sun",
  'Lun':"Mon", 'Mar':"Tue", 'Mié':"Wed", 'Jue':"Thu", 'Vie':"Fri", 'Sáb':"Sat",
  "Información adicional": "Business information",
  "Mis direcciones":"Delivery addresses",
  "Registro":"Create an account",
  "OTP": "OTP",
  "Agotado": "Out of stock",
  "bienvenidotittle" : "Hey,",
  "bienvenidodescr" : "what's up?",
  "Pedido programado para":"Order set for",
  "carritoeatin":"View cart",
  "Selecciona tu método de pago":"Select a payment method",
  "Promociones": "Promotions",
  "Ingresar":"Login",
  "Borrarcarrito":"Delete all cart",
  "Agregar ¢": "Add ¢",
  "Ver pedido": "View cart",
  "Bienvenido": "Welcome",
  "Bienvenido de regreso": "Welcome back",
  "Crea tu cuenta para continuar":"Create an account to continue",
  "Ingresa tu cuenta para continuar":"Enter your credentials to continue",
  "Inicio de sesión exitoso":"Successful login",
  "Error de inicio de sesión":"Login error",
  "Esperando a que el negocio acepte su pedido": "Waiting for the business to accept your order",
  "El negocio está preparando su producto.": "The business is preparing its product.",
  " está en camino para recoger su pedido":" is on its way to pick up your order",
  " llegó al negocio":" reached business",
  " ha recogido tu pedido":" has picked up your order",
  "Su orden ha sido completada":"Your order has been completed",
  "Su pedido ha sido cancelado": "Your order has been canceled",
  "Iniciar sesión":"Log in",
  "Registrarse": "Register",
  "Perfil": "Profile",
  "Mis Pedidos": "My Orders",
  "Cerrar sesión": "Log out",
  "Hogar": "Home",
  "Su cupón ha sido agregado con éxito.": "Your coupon has been successfully added.",
  "Añade al menos ":"Add atleast ",
  " productos promocionales.": " promotional products.",
  "Los productos máximos deben ser ¢":"Max products should be ¢",
  "Los productos mínimos deben ser ¢": "Minimum products must be ¢",
  "Este cupón se ha utilizado recientemente, inténtalo de nuevo más tarde.":"This coupon has been used recently, please try again later.",
  " Costo de envío: ":" Delivery fee: ",
  " Descuento: ": " Discount: ",
  "Seleccionar mi ubicación": "Enter a delivery location",
  "Buscar producto": "Search product",
  "ID del pedido: ": "Order ID: ",
  "Obligatorio" :"Mandatory",
  "Selecciona al menos ": "Select at least ",
  "Selecciona de ": "Select from ",
  ".Hasta ": ".Till ",
  "Selecciona hasta ": "Select up to ",
  "Email": "Email",
  "Contraseña":"Password",
  "o":"or",
  "Nombre completo": "Full name",
  "Número de teléfono":"Phone number",
  "Datos correctos":"Correct data",
  "Existe un error en el registro":"There is an error in the registration",
  "Crear cuenta": "Create account",
  "Cambiar número Éxito":"Change number Success",
  "Reenviar":"Resend",
  "Mensaje enviado correctamente al número anterior":"Message successfully sent to previous number",
  "Has ingresado correctamente":"You have entered correctly",
  "Envío OTP con éxito":"OTP Send Successfully",
  "El código ingresado no es correcto, intenta nuevamente":"The code entered is not correct, please try again",
  "Calificación:":"Rating:",
  "Correo:":"Email:",
  "Dirección:":"Address:",
  "Selecciona tu ubicación o ingrese una nueva dirección":"Select your location or enter a new address",
  "Ingrese la dirección de entrega":"Enter the delivery address",
  "Detalles adicionales":"Additional Details",
  "mi hogar":"my home",
  "mi Trabajo":"my work",
  "otra dirección":"a visit address",
  "Trabajo":"Work",
  "Visita":"Visit",
  "Cancelar":"Cancel",
  "Cancel":"Cancel",
  "Guardar":"Save",
  "Usar mi ubicación actual":"Use the current location",
  "Agregar nueva tarjeta":"Add new card",
  "Pago en efectivo":"Cash payment",
  "Tarjeta a la entrega":"Card on finalize",
  ". Ver detalles":". See details",
  ". Menos detalles":". Less details",
  "Comprar ":"Buy ",
  " productos y pago ":" products and pay for ",
  " en productos seleccionados":" on selected products",
  "Productos promocionados:":"Promoted Products:",
  "Se eliminará la tarifa de envío":"Delivery fee will be removed",
  "El número mínimo de productos debe ser ¢":"The minimum total of products must be ¢",
  "El número máximo de productos debe ser ¢":"The maximum total of products must be ¢",
  "Descuento ¢":"Discount ¢",
  "Obtener ¢":"Get ¢",
  " al final del día en Wallet":" at the end of the day in Wallet",
  "Obtener ":"Get ",
  "% de cashback sobre el total de tus productos en Wallet":"% of cashback on the total of your products in Wallet",
  "% de descuento en todos sus productos.":"% discount on all your products.",
  "Consigue un descuento mínimo de ¢":"Get a minimum discount of ¢",
  "Sube hasta ¢":"Up to ¢",
  " descuento":" discount",
  "Aplicar":"Apply",
  "Lo sentimos, no hay descuentos disponibles en este momento.":"Sorry, there are no discounts available at this time.",
  "Entrega de:":"Delivery:",
  "Recoger en:":"Pick up in:",
  "min aprox":"min approx",
  "costo de envío: ¢":"Delivery fee: ¢",
  "Distancia":"Distance",
  "Teléfono":"Phone:",
  " ¡Promociones! ":" Promotions! ",
  "Para recoger tu pedido debes dirigirte a:":"To collect your order you must go to:",
  "Entrega a domicilio":"Delivery",
  "Recoger en tienda":"Pickup",
  "Menú":"Menu",
  "Información":"Information",
  "Informaciónshort":"Info",
  "Tu pedido":"My order",
  "Entregar a ":"Deliver to ",
  "Agregar instrucciones de entrega":"Add delivery instructions",
  "Para recoger tu pedido debes dirigirte a":"To pick up your order you must go to",
  "Ver el mapa":"View map",
  "Sí enviar cubiertos":"Add cutlery",
  "No envíar cubiertos":"Do not add cutlery",
  "Agregar instrucciones de preparación":"Extra instructions for the kitchen",
  "Selecciona un cupón de descuento":"Select a discount coupon",
  "Borrar":"Remove",
  "Agregar propina para el repartidor":"Add tip for driver",
  "¡Las entregas solo son posible gracias a ellos!":"Deliveries are only possible thanks to them!",
  "Todos los productos":"Subtotal",
  "Tarifa de servicio":"Service fee",
  "Costo de envío":"Delivery fee",
  "Descuento": "Discount",
  "Propina":"Tip",
  "Método de pago":"Payment method",
  "Total":"Total",
  "Elige tu método de pago ":"Choose your payment",
  "Esperando a que el negocio acepte el pedido":"Waiting for the business to accept the order",
  "Nuevo pedido de ":"New order from ",
  "Nuevo orden":"New order",
  "Pedido realizado con éxito":"Order made successfully",
  "Algo salió mal al crear el pedido":"Something went wrong when creating the order",
  "El negocio está cerrado":"Business is closed",
  "Pagar pedido ":"Pay and order",
  "Hacer pedido ":"Place order",
  "Detalles del pedido":"Order details",
  "Cancelar pedido":"Cancel order",
  "Pedido número":"Order ID",
  "ID del pedido":"Order ID",
  "Entrega estimada": "Estimate delivery",
  "Recogida estimada":"Estimate pickup",
  "Ver más":"See more",
  "Te avisaremos una vez que el negocio acepte tu pedido":"We will notify you once the business accepts your order",
  "El negocio está preparando tu pedido":"The business is preparing your order",
  "Tu pedido está siendo preparado":"Your order is being prepared",
  " está en camino para recoger tu pedido":" is on its way to pick up your order",
  " ha llegado al negocio y recogerá tu pedido":" has arrived at the business and will pick up your order",
  "Tu pedido ha sido entregado":"Your order has been delivered",
  "Tu pedido ha sido cancelado":"Your order has been canceled",
  "Tu ubicación":"Your location",
  "Nivel: ":"Level: ",
  "Dirección de entrega":"Delivery address",
  "Tarjeta contra entrega":"Card on delivery",
  "Efectivo":"Cash",
  "Agregar mesa":"Add your information",
  "Tarjeta":"Card",
  "Día y hora de su pedido":"Date and time of your order",
  "Enviar cubiertos":"Send cutlery",
  "Sí":"Yes",
  "No":"No",
  "Detalles del pedido:":"Order details:",
  "Total parcial":"Subtotal",
  "Pagado con ":"Paid with ",
  "tarjeta":"card",
  "Pagar en efectivo":"Pay cash",
  "Regresar":"Back",
  "Nombre":"Name",
  "Perfil actualizado correctamente":"Profile updated successfully",
  "Por favor ingresa de nuevo para actualizar tu contraseña":"Please login again to update your password",
  "Algo salió mal":"Something went wrong",
  "Actualizar Perfil":"Update profile",
  "Ahora puedes cambiar tu contraseña":"Now you can change your password",
  "No puedo iniciar sesión":"Cant login",
  "No existen pedidos para mostrar":"There are no orders to display",
  "Solicitar ID":"Order ID",
  "Tu orden ha sido entregada":"Your order has been delivered",
  "Pendiente":"Pending",
  "Tarjeta con terminación ":"Card with termination ",
  "Agregar tarjeta":"Add card",
  "Número de tarjeta":"Card number",
  "Nombre de la titular":"Holder name",
  "Ahora":"Now",
  " Hoy, ":" Today, ",
  " Mañana, ":" Tomorrow, ",
  "hoy":"today",
  "mańana":"tomorrow",
  "Guardar tarjeta":"Save card",
  "Programar tu entrega":"Schedule your delivery",
  "Tu pedido llegará entre en lapso seleccionado. Faltando 40 minutos para su entrega, tu pedido no podrá ser cancelado":"Your order will arrive within the selected period. With 40 minutes left for delivery, your order cannot be canceled",
  "Programar y avanzar":"Schedule and advance",
  "El banco ha rechazado la transacción. Crea e ingresa aquí una tarjeta digital para realizar compras más seguras":"The bank has declined the transaction. Create and enter a digital card here to make more secure purchases",
  "Los datos de la tarjeta son incorrectos. Verifíquelos para continuar.":"The card details are incorrect. Please verify them to continue.",
  "Algo salió mal al crear el pago":"Something went wrong creating the payment",
  "Hubo un error al confirmar el pago. Detalles: ":"There was an error confirming the payment. Details: ",
  "Hubo un error al confirmar el pago. Vuelve a intentarlo con otra tarjeta":"There was an error confirming the payment. Please try again with another card",
  "El pago aún se encuentra en estado de \"procesamiento\". Esto es inusual por favor contáctenos":"Payment is still in \" processing \" status . This is unusual please contact us",
  "Lo sentimos, no tenemos productos disponibles para comprar en este momento, intenta en otra hora":"Sorry, we don't have products available for purchase at this moment, please try another time",
  "Comer en":"Eat in",
  "Información opcional":"Table info",
  "Número de mesa":"Table number (optional)",
  "Mesa": "Table number",
  "Nombre del cliente":"Client name",
  "Cliente": "Guest name",
  "telefono":"Teléfono",
  "Ingresar dirección":"Add address",
  "Por favor ingresa tu dirección":"Add your address",
  "Añadir más productos":"Add more products",
  "La compra mínima debe ser de ¢":"Minimum purchase should be ¢",
  "No puedo iniciar sesión, actualice la página":"Cant login, please refresh the page",
  "Gratis":"Free",
  "Buscando direcciones":"Searching for addresses...",
  "Cambiar el tipo de entrega borrará su carrito. ¿Estas seguro que deseas continuar?":"Change delivery type will clear your cart. Are you sure you want to proceed?",
  "Pago con Criptomoneda": "Pay with Cryptocurrency"
 },
 es: {
   'language':"es",
    'Dom':"Dom",
    'Lun':"Lun", 'Mar':"Mar", 'Mié':"Mié", 'Jue':"Jue", 'Vie':"Vie", 'Sáb':"Sáb",
    "Información adicional": "Información del negocio",
    "Informaciónshort":"Info",
    "Mis direcciones":"Direcciones de entrega",
    "Registro":"Crear una cuenta",
    "OTP": "OTP",
    "Agotado": "Agotado",
    "bienvenidotittle" : "Hey,",
    "bienvenidodescr" : "que hay de nuevo?",
    "Cliente": "Nombre del invitado",
    "Mesa": "Número de mesa",
    "Selecciona tu método de pago":"Selecciona el método de pago",
    "Promociones": "Promociones",
    "Ingresar":"Ingresar",
    "Inicio de sesión exitoso":"Inicio de sesión exitoso",
    "Error de inicio de sesión":"Error de inicio de sesión",
    "Esperando a que el negocio acepte su pedido": "Esperando a que el negocio acepte su pedido",
    "El negocio está preparando su producto.": "El negocio está preparando su producto.",
    " está en camino para recoger su pedido":" está en camino para recoger su pedido",
    " ha recogido tu pedido":" ha recogido tu pedido",
    "Su orden ha sido completada":"Su orden ha sido completada",
    "Su pedido ha sido cancelado": "Su pedido ha sido cancelado",
    "Iniciar sesión":"Iniciar sesión",
    "Registrarse": "Registrarse",
    "Perfil": "Perfil",
    "Mis Pedidos": "Mis Pedidos",
    "Cerrar sesión": "Cerrar sesión",
    "Hogar": "Hogar",
    "Su cupón ha sido agregado con éxito.": "Su cupón ha sido agregado con éxito.",
    "Añade al menos ":"Añade al menos ",
    " productos promocionales.": " productos promocionales.",
    "Los productos máximos deben ser ¢":"Los productos máximos deben ser ¢",
    "Los productos mínimos deben ser ¢": "Los productos mínimos deben ser ¢",
    "Este cupón se ha utilizado recientemente, inténtalo de nuevo más tarde.":"Este cupón se ha utilizado recientemente, inténtalo de nuevo más tarde.",
    " Costo de envío: ":" Costo de envío: ",
    " Descuento: ": " Descuento: ",
    "Seleccionar mi ubicación": "Ingresa una dirección de entrega",
    "Buscar producto": "Buscar producto",
    "ID del pedido: ": "ID del pedido: ",
    "Obligatorio" :"Obligatorio",
    "Selecciona al menos ": "Selecciona al menos ",
    "Selecciona de ": "Selecciona de ",
    ".Hasta ": ".Hasta ",
    "Selecciona hasta ": "Selecciona hasta ",
    "Email": "Email",
    "Contraseña":"Contraseña",
    "o":"o",
    "Bienvenido": "Bienvenido",
    "Bienvenido de regreso": "Bienvenido de regreso",
    "Crea tu cuenta para continuar":"Crea una cuenta para continuar",
    "Ingresa tu cuenta para continuar":"Ingresa tu cuenta para continuar",
    "Nombre completo": "Nombre completo",
    "Número de teléfono":"Número de teléfono",
    "Datos correctos":"Datos correctos",
    "Existe un error en el registro":"Existe un error en el registro",
    "Crear cuenta": "Crear cuenta",
    "Cambiar número Éxito":"Cambiar número Éxito",
    "Reenviar":"Reenviar",
    "Mensaje enviado correctamente al número anterior":"Mensaje enviado correctamente al número anterior",
    "Has ingresado correctamente":"Has ingresado correctamente",
    "El código ingresado no es correcto, intenta nuevamente":"El código ingresado no es correcto, intenta nuevamente",
    "Calificación:":"Calificación:",
    "Correo:":"Correo:",
    "Dirección:":"Dirección:",
    "Selecciona tu ubicación o ingrese una nueva dirección":"Selecciona la ubicación o ingresa una nueva dirección",
    "Ingrese la dirección de entrega":"Ingrese la dirección de entrega",
    "Detalles adicionales":"Detalles adicionales",
    "mi hogar":"mi hogar",
    "mi Trabajo":"mi trabajo",
    "otra dirección":"domicilio",
    "Trabajo":"Trabajo",
    "Visita":"Visita",
    "Cancelar":"Cancelar",
    "Cancel":"Cancelar",
    "Guardar":"Guardar",
    "Usar mi ubicación actual":"Usar la ubicación actual",
    "Agregar nueva tarjeta":"Agregar nueva tarjeta",
    "Pago en efectivo":"Pago en efectivo",
    "Tarjeta a la entrega":"Tarjeta al finalizar",
    ". Ver detalles":". Ver detalles",
    ". Menos detalles":". Menos detalles",
    "Comprar ":"Comprar ",
    " productos y pago ":" productos y pago ",
    " en productos seleccionados":" en productos seleccionados",
    "Productos promocionados:":"Productos promocionados:",
    "Se eliminará la tarifa de envío":"Se eliminará la tarifa de envío",
    "El número mínimo de productos debe ser ¢":"El número mínimo de productos debe ser ¢",
    "El número máximo de productos debe ser ¢":"El número máximo de productos debe ser ¢",
    "Descuento ¢":"Descuento ¢",
    "Obtener ¢":"Obtener ¢",
    " al final del día en Wallet":" al final del día en Wallet",
    "Obtener ":"Obtener ",
    "% de cashback sobre el total de tus productos en Wallet":"% de cashback sobre el total de tus productos en Wallet",
    "% de descuento en todos sus productos.":"% de descuento en todos sus productos.",
    "Consigue un descuento mínimo de ¢":"Consigue un descuento mínimo de ¢",
    "Sube hasta ¢":"Sube hasta ¢",
    " descuento":" descuento",
    "Aplicar":"Aplicar",
    "Lo sentimos, no hay descuentos disponibles en este momento.":"Lo sentimos, no hay descuentos disponibles en este momento.",
    "Entrega de:":"Entrega de:",
    "Recoger en:":"Recoger en:",
    "min aprox":"min aprox",
    "Pedido programado para":"Pedido programado para",
    "costo de envío: ¢":"Costo de envío: ¢",
    "Distancia":"Distancia",
    " ¡Promociones! ":" ¡Promociones! ",
    "Para recoger tu pedido debes dirigirte a:":"Para recoger tu pedido debes dirigirte a:",
    "Entrega a domicilio":"Entrega a domicilio",
    "Recoger en tienda":"Recoger en tienda",
    "Menú":"Menú",
    "Información":"Información",
    "Tu pedido":"Mi pedido",
    "carritoeatin":"Ver carrito",
    "Entregar a ":"Dirección actual de entrega",
    "Agregar mesa":"Agrega tu información",
    "Agregar instrucciones de entrega":"Agregar instrucciones de entrega",
    "Para recoger tu pedido debes dirigirte a":"Para recoger tu pedido debes dirigirte a",
    "Ver el mapa":"Ver el mapa",
    "Sí enviar cubiertos":"Sí agregar cubiertos",
    "No envíar cubiertos":"No agregar cubiertos",
    "Agregar instrucciones de preparación":"Instrucciones extras para la cocina",
    "Selecciona un cupón de descuento":"Selecciona un cupón de descuento",
    "Borrar":"Borrar",
    "Borrarcarrito":"Eliminar carrito",
    "Agregar propina para el repartidor":"Agregar propina para el repartidor",
    "¡Las entregas solo son posible gracias a ellos!":"¡Las entregas solo son posible gracias a ellos!",
    "Todos los productos":"Subtotal",
    "Tarifa de servicio":"Tarifa de servicio",
    "Costo de envío":"Costo de envío",
    "Descuento": "Descuento",
    "Propina":"Propina",
    "Método de pago":"Método de pago",
    "Total":"Total",
    "Elige tu método de pago ":"Elige tu método de pago ",
    "Esperando a que el negocio acepte el pedido":"Esperando a que el negocio acepte el pedido",
    "Nuevo pedido de ":"Nuevo pedido de ",
    "Nuevo orden":"Nuevo orden",
    "Ver pedido": "Ver carrito",
    "Pedido realizado con éxito":"Pedido realizado con éxito",
    "Algo salió mal al crear el pedido":"Algo salió mal al crear el pedido",
    "El negocio está cerrado":"El negocio está cerrado",
    "Pagar pedido ":"Pagar pedido ",
    "Hacer pedido ":"Hacer pedido ",
    "Teléfono":"Teléfono:",
    "Detalles del pedido":"Detalles del pedido",
    "Cancelar pedido":"Cancelar pedido",
    "Pedido número":"Pedido número",
    "ID del pedido":"ID del pedido",
    "Entrega estimada": "Entrega estimada",
    "Recogida estimada":"Recogida estimada",
    "Ver más":"Ver más",
    "Te avisaremos una vez que el negocio acepte tu pedido":"Te avisaremos una vez que el negocio acepte tu pedido",
    "El negocio está preparando tu pedido":"El negocio está preparando tu pedido",
    "Tu pedido está siendo preparado":"Tu pedido está siendo preparado",
    " está en camino para recoger tu pedido":" está en camino para recoger tu pedido",
    " llegó al negocio":" llegó al negocio",
    " ha llegado al negocio y recogerá tu pedido":" ha llegado al negocio y recogerá tu pedido",
    "Tu pedido ha sido entregado":"Tu pedido ha sido entregado",
    "Tu pedido ha sido cancelado":"Tu pedido ha sido cancelado",
    "Tu ubicación":"Tu ubicación",
    "Nivel: ":"Nivel: ",
    "Dirección de entrega":"Dirección de entrega",
    "Tarjeta contra entrega":"Tarjeta contra entrega",
    "Efectivo":"Efectivo",
    "Tarjeta":"Tarjeta",
    "Día y hora de su pedido":"Día y hora de su pedido",
    "Enviar cubiertos":"Enviar cubiertos",
    "Sí":"Sí",
    "No":"No",
    "Detalles del pedido:":"Detalles del pedido:",
    "Total parcial":"Total parcial",
    "Pagado con ":"Pagado con ",
    "tarjeta":"tarjeta",
    "Pagar en efectivo":"Pagar en efectivo",
    "Regresar":"Regresar",
    "Nombre":"Nombre",
    "Agregar ¢": "Agregar ¢",
    "Perfil actualizado correctamente":"Perfil actualizado correctamente",
    "Por favor ingresa de nuevo para actualizar tu contraseña":"Por favor ingresa de nuevo para actualizar tu contraseña",
    "Algo salió mal":"Algo salió mal",
    "Actualizar Perfil":"Actualizar Perfil",
    "Ahora puedes cambiar tu contraseña":"Ahora puedes cambiar tu contraseña",
    "No puedo iniciar sesión":"No puedo iniciar sesión",
    "No existen pedidos para mostrar":"No existen pedidos para mostrar",
    "Solicitar ID":"Solicitar ID",
    "Tu orden ha sido entregada":"Tu orden ha sido entregada",
    "Pendiente":"Pendiente",
    "Tarjeta con terminación ":"Tarjeta con terminación ",
    "Agregar tarjeta":"Agregar tarjeta",
    "Número de tarjeta":"Número de tarjeta",
    "Nombre de la titular":"Nombre de la titular",
    "El banco ha rechazado la transacción. Crea e ingresa aquí una tarjeta digital para realizar compras más seguras":"El banco ha rechazado la transacción. Crea e ingresa aquí una tarjeta digital para realizar compras más seguras",
    "Los datos de la tarjeta son incorrectos. Verifíquelos para continuar.":"Los datos de la tarjeta son incorrectos. Verifíquelos para continuar.",
    "Algo salió mal al crear el pago":"Algo salió mal al crear el pago",
    "Ahora":"Ahora",
    " Hoy, ":" Hoy, ",
    " Mañana, ":" Mañana, ",
    "hoy":"Hoy",
    "mańana":"Mañana",
    "Programar tu entrega":"Programar tu entrega",
    "Tu pedido llegará entre en lapso seleccionado. Faltando 40 minutos para su entrega, tu pedido no podrá ser cancelado":"Tu pedido llegará entre en lapso seleccionado. Faltando 40 minutos para su entrega, tu pedido no podrá ser cancelado",
    "Programar y avanzar":"Programar y avanzar",
    "Hubo un error al confirmar el pago. Detalles: ":"Hubo un error al confirmar el pago. Detalles: ",
    "Hubo un error al confirmar el pago. Vuelve a intentarlo con otra tarjeta":"Hubo un error al confirmar el pago. Vuelve a intentarlo con otra tarjeta",
    "El pago aún se encuentra en estado de \"procesamiento\". Esto es inusual por favor contáctenos":"El pago aún se encuentra en estado de \"procesamiento\". Esto es inusual por favor contáctenos",
    "Lo sentimos, no tenemos productos disponibles para comprar en este momento, intenta en otra hora":"Lo sentimos, no tenemos productos disponibles para comprar en este momento, intenta en otra hora",
    "Comer en":"Comer aquí",
    "Información opcional":"Información de mesa",
    "Número de mesa":"Número de mesa (opcional)",
    "Nombre del cliente":"Nombre del cliente",
    "telefono":"Teléfono",
    "Ingresar dirección":"Ingresar dirección",
    "Por favor ingresa tu dirección":"Ingresa tu dirección",
  "Añadir más productos":"Añadir más productos",
  "La compra mínima debe ser de ¢":"La compra mínima debe ser de ¢",
  "No puedo iniciar sesión, actualice la página":"No puedo iniciar sesión, actualice la página",
  "Gratis":"Gratis",
  "Buscando direcciones":"Buscando direcciones...",
  "Cambiar el tipo de entrega borrará su carrito. ¿Estas seguro que deseas continuar?":"Cambiar el tipo de entrega borrará el carrito. ¿Estás seguro que deseas continuar?",
  "Pago con Criptomoneda":"Pago con Criptomoneda"
 }
});
