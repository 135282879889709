import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Detail,{Orders, Profile} from './App';
import reportWebVitals from './reportWebVitals';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import {BrowserRouter, Routes,Route} from 'react-router-dom';
import Main from './Main';

const root = ReactDOM.createRoot(document.getElementById('root'));
const theme = createTheme({
  palette: {
    secondary: {
      main: '#272727'
    },
    // primary: {
    //   main:'#44cf8e'
    // }
  }
});
root.render(
  <ThemeProvider theme={theme}>
  <BrowserRouter>
  <Routes>
            <Route path="/business/:id" element={<Detail />}/> 
            <Route path="/profile" element={<Main />}/> 
            <Route path="/profile/:id" element={<Profile />}/> 
            <Route path="/my-orders" element={<Main />}/> 
            <Route path="/my-orders/:id" element={<Orders />}/> 
            <Route path="/" element={<Main />}/> 
            <Route path="/business/" element={<Main />}/> 
</Routes>
  </BrowserRouter>
  </ThemeProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
